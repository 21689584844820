(function (angular, undefined) {
    "use strict";
    var links = angular.module("mikesLinks");

    links.factory("LinkService", ["$http", "$q", "$localStorage",
        function ($http, $q, $localStorage) {
        var getQueryString = function () {
            var date;
            if ($localStorage.links instanceof Array && $localStorage.links.length > 0) {
                date = _.first($localStorage.links.sort(function (a, b) {
                    return (new Date(b.created)).getTime() - (new Date(a.created)).getTime();
                }));
                if (_.has(date, "date")) {
                    return "?after=" + (new Date(date.created)).getTime();
                }
            }
            return "";
        };

        return {
            "getLinks": function () {
                var def = $q.defer(),
                    query = getQueryString();

                $http.get("/api/link/list" + query)
                    .then(function (res) {
                        if (!query) {
                            $localStorage.links = [];
                        }
                        if (res.data.length > 0) {
                            $localStorage.links = _.uniqBy(
                                _.union(res.data, $localStorage.links),
                                function (item) { return item._id; }
                            ).sort(function (a, b) {
                                return (new Date(b.created)).getTime() - (new Date(a.created)).getTime();
                            }).filter(function (item) { return !item.hidden; });
                        }
                        def.resolve();
                    })
                    .catch(function (err) {
                        def.reject();
                    });

                return def.promise;
            }
        };
    }]);

})(angular);
